import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Snackbar, Alert, Paper } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

const FeedbackPage = ({ theme }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const feedbackData = {
      name,
      email,
      feedback,
    };
  
    try {
      const response = await fetch(`${apiUrl}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedbackData),
      });
  
      if (response.ok) {
        setOpenSnackbar(true);
        setName('');
        setEmail('');
        setFeedback('');
      } else {
        console.error('Error submitting feedback');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom align="center">
            We value your feedback!
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Please let us know how we can improve or what you think of our service.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              InputProps={{
                sx: { backgroundColor: '#ffffff' },
              }}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              type="email"
            />
            <TextField
              fullWidth
              label="Your Feedback"
              variant="outlined"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              margin="normal"
              multiline
              rows={4}
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "25px",
                  backgroundColor: "#0FBEB7",
                  textTransform: "capitalize",
                  width: "50%",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    color: "#757575",
                  },
                }}
              >
                Submit Feedback
              </Button>
            </Box>
          </Box>

          {/* Snackbar for feedback confirmation */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
              Thank you for your feedback!
            </Alert>
          </Snackbar>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
};

export default FeedbackPage;
