import App from "./routes/App"
import Home from "./routes/Home"
import Veterinarians from "./routes/Veterinarians";
import VetProfile from "./routes/VetProfile";
import Appointments from "./routes/Appointments";
import AptDetails from "./routes/AptDetails";
import Differentials from "./routes/Differentials";
import VetSignup from "./routes/VetSignup";
import OwnerSignup from "./routes/OwnerSignup";
import PracticeAdminSignup from "./routes/PracticeAdminSignup";
import PracticeCodeCheck from "./routes/PracticeCodeCheck";
import BrickAndMortarSignup from "./routes/BrickAndMortarSignup";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Pets from "./routes/Pets";
import ForgotPassword from "./routes/ForgotPassword";
import AccountSettings from "./routes/AccountSettings";
import AdminAccountSettings from "./routes/AdminAccountSettings";
import NewAdminWarning from "./routes/NewAdminWarning";
import AboutPage from "./components/AboutPage";
import { createTheme } from '@mui/material/styles';
import '@fontsource/sora'; 
import FeedbackPage from "./routes/FeedbackPage";

const theme = createTheme({
  palette: {
    primary: {
      main: '#BDE9FB',  
    },
    secondary: {
      main: '#3991da',  
    },
  },
  typography: {
    fontFamily: 'Sora, Arial, sans-serif',
  },
});

const routes = [
    {
      path: "/",
      element: <App theme={theme} />,
      children: [
        {
          path: "/",
          element: <Home theme={theme} />,
        },
        {
          path: "/about",
          element: <AboutPage theme={theme} />
        },
        {
            path: "/veterinarians",
            element: <Veterinarians theme={theme} />
        },
        {
          path: "/veterinarian/:id",
          element: <VetProfile theme={theme} />
        },
        {
          path: "/appointments",
          element: <Appointments theme={theme} />
        },
        {
          path: "/appointment/:id",
          element: <AptDetails theme={theme} />
        },
        {
          path: "/differentials/:id",
          element: <Differentials theme={theme} />
        },
        {
          path: "/pets",
          element: <Pets theme={theme} />
        },
        {
          path: "/vet-signup",
          element: <VetSignup theme={theme} />
        },
        {
          path: "/brick-and-mortar-signup",
          element: <BrickAndMortarSignup theme={theme} />
        },
        {
          path: "/owner-signup",
          element: <OwnerSignup theme={theme} />
        },
        {
          path: "/practice-admin-signup",
          element: <PracticeAdminSignup theme={theme} />
        },
        {
          path: "/practice-code-check",
          element: <PracticeCodeCheck theme={theme} />
        },
        {
          path: "/login",
          element: <Login theme={theme} />
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword theme={theme} />
        },
        {
          path: "/account-settings",
          element: <AccountSettings theme={theme} />
        },
        {
          path: "/admin-account-settings",
          element: <AdminAccountSettings theme={theme} />
        },
        {
          path: "/new-admin-warning",
          element: <NewAdminWarning theme={theme} />
        },
        {
          path: "/feedback-page",
          element: <FeedbackPage theme={theme} />
        },
        {
          path: "/logout",
          element: <Logout theme={theme} />
        }
      ],
    },
  ];
  
  export default routes;