import { useState, useEffect, useCallback } from "react";
import {
  fetchVetSubStatus,
  getPets,
  getVeterinarianId,
  handleAppointmentSubmit,
  newPetSubmit,
} from "./api";
import SelectPetMenu from "./SelectPetMenu";
import NewPetForm from "./NewPetForm";
import PremiumUpgrade from "./PremiumUpgrade";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
  Select,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function NewAppointment({ open, handleClose, petIds, onAppointmentCreated }) {
  const [pets, setPets] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    pet_id: "",
    date: "",
    time: "",
    reason: "",
    meeting_link: "In Office Visit",
    veterinarian_id: "",
  });
  const [isAddingNewPet, setIsAddingNewPet] = useState(false);
  const [ageType, setAgeType] = useState("years");
  const [newPet, setNewPet] = useState({
    name: "",
    age: "",
    breed: "",
    sex: "",
    weight: "",
  });
  const [vetSubStatus, setVetSubStatus] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const token = localStorage.getItem("token");
  const grabToken = () => {
    if (!token) {
      navigate("/login");
      return;
    }
    return token;
  };

  useEffect(() => {
    if (open) {
      fetchPets();
      fetchVeterinarianId();
    }
  }, [open]);

  useEffect(() => {
    grabToken();
    fetchVetSubStatus(apiUrl, token, setVetSubStatus);
  }, [open]);

  const fetchPets = useCallback(
    () => getPets(apiUrl, setPets, navigate, petIds, grabToken, token),
    [apiUrl, setPets, navigate, petIds]
  );

  const fetchVeterinarianId = useCallback(
    () => getVeterinarianId(apiUrl, setNewAppointment, navigate, token),
    [navigate, setNewAppointment, apiUrl]
  );

  function handleChange(e) {
    if (e.target.name === "pet_id" && e.target.value === "new") {
      setIsAddingNewPet(true);
      setNewAppointment({ ...newAppointment, pet_id: "" });
    } else {
      setNewAppointment({
        ...newAppointment,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleNewPetChange(e) {
    setNewPet({ ...newPet, [e.target.name]: e.target.value });
  }

  const handleNewPetSubmit = useCallback(
    () =>
      newPetSubmit(
        token,
        setPets,
        setNewAppointment,
        setIsAddingNewPet,
        setNewPet,
        newPet,
        apiUrl,
        pets,
        newAppointment
      ),
    [
      newPet,
      pets,
      setPets,
      setIsAddingNewPet,
      setNewPet,
      newAppointment,
      navigate,
      apiUrl,
    ]
  );

  const handleSubmit = useCallback(
    (e) =>
      handleAppointmentSubmit(
        e,
        token,
        grabToken,
        apiUrl,
        handleClose,
        onAppointmentCreated,
        setNewAppointment,
        isAddingNewPet,
        newAppointment,
        navigate
      ),

    [
      isAddingNewPet,
      newAppointment,
      handleClose,
      onAppointmentCreated,
      setNewAppointment,
      navigate,
      apiUrl,
    ]
  );

  const handleNavToAccountPage = () => {
    navigate("/account-settings");
  };

  const handleAgeTypeChange = (event) => {
    setAgeType(event.target.value);
  };

  const closeAddNewPetDialogue = () => {
    setIsAddingNewPet(!isAddingNewPet)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      {vetSubStatus === "ok to proceed" ? (
        <>
          <DialogTitle>New Appointment</DialogTitle>
          <DialogContent>
            <Box component="form" onSubmit={handleSubmit}>
              <SelectPetMenu
                newAppointment={newAppointment}
                handleChange={handleChange}
                isAddingNewPet={isAddingNewPet}
                pets={pets}
              />
              {isAddingNewPet && (
                <NewPetForm
                  newPet={newPet}
                  handleNewPetChange={handleNewPetChange}
                  handleAgeTypeChange={handleAgeTypeChange}
                  handleNewPetSubmit={handleNewPetSubmit}
                  ageType={ageType}
                  closeAddNewPetDialogue={closeAddNewPetDialogue}
                />
              )}
              <TextField
                label="Date"
                name="date"
                type="date"
                value={newAppointment.date}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Time"
                name="time"
                type="time"
                value={newAppointment.time}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Reason for Visit"
                name="reason"
                value={newAppointment.reason}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleSubmit}
              disabled={isAddingNewPet}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      ) : (
        <PremiumUpgrade handleNavToAccountPage={handleNavToAccountPage} />
      )}
    </Dialog>
  );
}

export default NewAppointment;
