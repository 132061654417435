import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import {
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

function OwnerAppointmentRow({ apt, theme, setFilteredApts, filteredApts }) {
  const [summaryExists, setSummaryExists] = useState(false);
  const [differentialsExist, setDifferentialsExist] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const datetime_utc = new Date(apt.datetime_utc + "Z");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };

  const localDateTime = datetime_utc.toLocaleString("en-US", options);
  const [date, time] = localDateTime.split(", ");

  const checkMedicalSummary = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-medical-summary/${apt.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSummaryExists(data.exists);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error(err);
    }
  }, [apt.id, navigate, apiUrl]);

  const checkDifferentialList = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-differential-list/${apt.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDifferentialsExist(data.exists);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error(err);
    }
  }, [apt.id, navigate, apiUrl]);

  useEffect(() => {
    checkMedicalSummary();
    checkDifferentialList();
  }, [checkMedicalSummary, checkDifferentialList]);

  const handleDeleteAppointment = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      // Step 1: Delete appointment from Calendly
      const calendlyEventUri = `${apt.calendly_event_uri}/cancellation`; // Retrieve the stored Calendly event URI from the appointment
      if (calendlyEventUri) {
        const calendlyResponse = await fetch(calendlyEventUri, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`, // Make sure your API key is correctly set
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ reason: deleteReason }),
        });

        if (!calendlyResponse.ok) {
          throw new Error("Failed to delete appointment from Calendly");
        }
        console.log("Appointment deleted from Calendly");
      }

      // Step 2: Delete appointment from local database
      const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(
            "Failed to delete appointment from the local database"
          );
        }
      } else {
        console.log("Appointment deleted from local database");
      }

      setFilteredApts((prevFilteredApts) =>
        prevFilteredApts.filter((a) => a.id !== apt.id)
      );
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const handleUpdateAppointment = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      // Step 1: Delete appointment from Calendly
      const calendlyEventUri = `${apt.calendly_event_uri}/cancellation`; // Retrieve the stored Calendly event URI from the appointment
      if (calendlyEventUri) {
        const calendlyResponse = await fetch(calendlyEventUri, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`, // Make sure your API key is correctly set
            "Content-Type": "application/json",
          },
          data: '{"reason":"Guest cancelled through Ailovet - will reschedule"}',
        });

        if (!calendlyResponse.ok) {
          throw new Error("Failed to delete appointment from Calendly");
        }
        console.log("Appointment deleted from Calendly");
      }
      // Step 2: Delete appointment from local database
      const response = await fetch(`${apiUrl}/api/v1/appointment/${apt.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(
            "Failed to delete appointment from the local database"
          );
        }
      } else {
        console.log("Appointment deleted from local database");
      }

      setFilteredApts((prevFilteredApts) =>
        prevFilteredApts.filter((a) => a.id !== apt.id)
      );

      // Step 3: navigate to scheduling page for that veterinarian
      navigate(`/veterinarian/${apt.veterinarian_id}`);
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          {apt.pet.name}
        </TableCell>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          {date}
        </TableCell>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          {time}
        </TableCell>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          {apt.reason}
        </TableCell>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          Dr. {apt.veterinarian.first_name} {apt.veterinarian.last_name}
        </TableCell>
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          {apt.status}
        </TableCell>

        {/* <TableCell>
          {summaryExists ? (
            <Link
              to={`/appointment/${apt.id}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                fontSize={isMobile ? "0.75rem" : "1rem"}
                variant="body2"
                sx={{
                  color: summaryExists ? "#3991da" : "#0fbeb7",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                View Summary
              </Typography>
            </Link>
          ) : (
            <Typography>Summary Pending</Typography>
          )}
        </TableCell> */}
        {/* <TableCell>
          {differentialsExist ? (
            <Link
              to={`/differentials/${apt.id}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                fontSize={isMobile ? "0.75rem" : "1rem"}
                variant="body2"
                sx={{
                  color: differentialsExist ? "#3991da" : "#0fbeb7",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                View Differentials
              </Typography>
            </Link>
          ) : (
            <Typography>Differentials Pending</Typography>
          )}
        </TableCell> */}
        <TableCell sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenUpdateModal(true)}
            sx={{
              borderRadius: "25px",
              backgroundColor: "#0FBEB7",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
          >
            Update
          </Button>
        </TableCell>
        <TableCell
          sx={{
            fontSize: isMobile ? "0.75rem" : "1rem",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpenDeleteModal(true)}
            sx={{
              borderRadius: "25px",
              backgroundColor: "#F94349",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
          >
            Cancel
          </Button>
        </TableCell>
      </TableRow>

      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Deleting an appointment is irreversible. Would you like to continue?
            If yes, please provide a reason for cancellation.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Cancellation Reason"
            fullWidth
            variant="outlined"
            value={deleteReason}
            onChange={(e) => setDeleteReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
              handleDeleteAppointment();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Update Modal */}
      <Dialog open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Once you update this appointment, your original appointment will be
            cancelled. Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpdateModal(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={() => {
              setOpenUpdateModal(false);
              handleUpdateAppointment();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OwnerAppointmentRow;
