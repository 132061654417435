import React, { useEffect, useState, useCallback } from "react";
import AdminAppointmentRow from "./AdminAppointmentRow";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableCell,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import DateFilter from "./DateFilter";
import PetNameFilter from "./PetNameFilter";
import VeterinarianFilter from "./VeterinarianFilter";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { fetchAppointments, fetchPetNames } from "./api"; // Import fetch function
import { useNavigate } from "react-router-dom";

export default function AdminAppointmentTable({ theme }) {
  const [apts, setApts] = useState([]);
  const [filteredApts, setFilteredApts] = useState([]); // Filtered appointments
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPetName, setSelectedPetName] = useState("");
  const [selectedVet, setSelectedVet] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [vetOptions, setVetOptions] = useState([]);
  const [petOptions, setPetOptions] = useState([]); // Pet name options
  const [inputValue, setInputValue] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch Appointments
  const fetchAllAppointments = useCallback(() => {
    fetchAppointments(apiUrl, token)
      .then((aptArray) => {
        setApts(aptArray); // Set full appointment list
        setFilteredApts(aptArray); // Initialize filtered appointments
        const uniqueVets = [
          ...new Set(
            aptArray.map(
              (apt) =>
                `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}`
            )
          ),
        ];
        setVetOptions(uniqueVets); // Set veterinarian options for filtering
      })
      .catch((err) => {
        console.error(err);
        if (err.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [apiUrl, token]);

  useEffect(() => {
    if (inputValue.length > 1) {
      const practiceAdminId = apts[0]?.veterinarian?.practice_admin_id; // Ensure there's an appointment
      fetchPetNames(apiUrl, token, inputValue, practiceAdminId)
        .then((petNames) => setPetOptions(petNames)) // Set pet name options for the autocomplete
        .catch((err) => console.error(err));
    }
  }, [inputValue, apiUrl, token, apts]);

  // Fetch appointments on component mount
  useEffect(() => {
    fetchAllAppointments();
  }, [fetchAllAppointments]);

  // Filtering Logic
  useEffect(() => {
    let filtered = [...apts];

    if (selectedPetName) {
      filtered = filtered.filter((apt) => apt.pet.name === selectedPetName);
    }

    if (selectedVet) {
      filtered = filtered.filter(
        (apt) =>
          `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ===
          selectedVet
      );
    }

    if (selectedDate) {
      filtered = filtered.filter((apt) => {
        const aptDate = new Date(apt.datetime_utc);
        return aptDate.toDateString() === selectedDate.toDateString();
      });
    }

    setFilteredApts(filtered);
  }, [apts, selectedPetName, selectedVet, selectedDate]);

  // Pagination Handlers
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetDate = () => {
    setSelectedPetName("");
    setSelectedVet("");
    setSelectedDate(null);
  };

  return (
    <Box sx={{ backgroundColor: "#BDE9FB", padding: isMobile ? 1 : 2 }}>
      <Typography
        variant="body1"
        sx={{
          color: "black",
          mb: isMobile ? 0.5 : 1,
          display: "inline-block",
          fontSize: isMobile ? "0.9rem" : "1rem",
        }}
      >
        Welcome, Admin
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          mb: isMobile ? 0.5 : 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{
              color: "black",
              mb: isMobile ? 0 : 1,
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            Appointments
          </Typography>
          <IconButton
            onClick={resetDate}
            sx={{
              ml: isMobile ? 1 : 1.5,
              mb: 1,
              backgroundColor: "white",
            }}
            size="small"
          >
            <RestartAltIcon />
          </IconButton>
        </Box>
      </Box>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "15px",
          marginTop: isMobile ? 3 : 0,
        }}
      >
        <TableContainer
          sx={{
            maxHeight: isMobile ? 1000 : 700,
            overflowX: "auto",
            overflowY: isMobile ? "auto" : "auto",
          }}
        >
          <Table stickyHeader aria-label="appointments table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    minWidth: isMobile ? 75 : 150,
                    width: isMobile ? 75 : 200,
                    whiteSpace: isMobile ? "normal" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <PetNameFilter
                    selectedPetName={selectedPetName}
                    setSelectedPetName={setSelectedPetName}
                    petOptions={petOptions} 
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    isMobile={isMobile}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    width: isMobile ? 75 : 200,
                    minWidth: isMobile ? 75 : 150,
                    whiteSpace: isMobile ? "none" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <DateFilter
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    isMobile={isMobile}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    minWidth: isMobile ? 75 : 150,
                    width: isMobile ? 75 : 150,
                    whiteSpace: isMobile ? "none" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    minWidth: isMobile ? 75 : 150,
                    whiteSpace: isMobile ? "none" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Reason for Visit
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    minWidth: isMobile ? 75 : 150,
                    whiteSpace: isMobile ? "none" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <VeterinarianFilter
                    selectedVet={selectedVet}
                    setSelectedVet={setSelectedVet}
                    vetOptions={vetOptions}
                    isMobile={isMobile}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: isMobile ? "0.75rem" : "1rem",
                    width: isMobile ? 75 : 150,
                    whiteSpace: isMobile ? "none" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredApts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((apt) => (
                  <AdminAppointmentRow theme={theme} key={apt.id} apt={apt} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredApts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={isMobile ? "" : "Rows per page"}
          rowsPerPageOptions={isMobile ? [] : [5, 10, 25, 50]}
          sx={{
            fontSize: isMobile ? "small" : "medium",
            ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
              fontSize: isMobile ? "small" : "medium",
            },
          }}
        />
      </Paper>
    </Box>
  );
}
