import { Outlet, useNavigate } from "react-router-dom"
import NavBar from "../components/NavBar";
import VetNavBar from "../components/VetNavBar";
import OwnerNavBar from "../components/OwnerNavBar";
import AdminNavBar from "../components/AdminNavBar";
import { useEffect, useState, useCallback } from "react";

function App() {
  const [userType, setUserType] = useState("")
  const navigate = useNavigate()
  const apiUrl = process.env.REACT_APP_API_URL || '';

  const checkSession = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${apiUrl}/api/v1/check_session`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Session check failed');
        }
      })
      .then(data => setUserType(data.user_type))
      .catch(error => {
        console.error('Error checking session:', error);
        handleLogout();
      });
    } else {
      setUserType("");
    }
  }, [apiUrl]);

  useEffect(() => {
    checkSession()
  }, [checkSession])

  const handleLogout = async () => {
    localStorage.removeItem('token');
    setUserType("")
    navigate('/')
  }

  const handleLogin = () => {
    checkSession();
  }

  return (
    <div className="app">
      {userType === 'vet' ? <VetNavBar />
        : userType === 'owner' ? <OwnerNavBar />
        : userType === 'admin' ? <AdminNavBar />
        : <NavBar />}
      <Outlet context={{handleLogin, handleLogout}}/>
    </div>
  );
}

export default App;