import React from "react";
import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CalendlyInlineEmbed from "../components/CalendlyInlineEmbed";

function VetProfile() {
    const params = useParams();
    const vetId = params.id;
    const [vetInfo, setVetInfo] = useState({
        id: 0,  
        first_name: '',
        last_name: '',
        degrees: '',
        profile: '',
        calendly_url: '',
        bio: '',
        city: '',
        state: '',
        zip_code: ''
    });
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const fetchVetInfo = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/vets/${vetId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const vet = await response.json();
                setVetInfo(vet);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error("Error fetching vet info:", error);
        }
    }, [vetId, navigate, apiUrl]);

    useEffect(() => {
        fetchVetInfo();
    }, [fetchVetInfo]);

    return (
        <>
            <div className="vet-profile-container">
                <h1 className="vet-profile-title">Book an appointment with {vetInfo.first_name} {vetInfo.last_name} {vetInfo.degrees}</h1>
                {/* <div className="vet-profile-card">
                        <img src={vetInfo.profile}
                            alt="profile-picture"
                        />
                        <div className="card-content">
                            <p>{vetInfo.city}, {vetInfo.state} {vetInfo.zip_code}</p>
                            <p>{vetInfo.specialty}</p>
                        </div>
                </div> */}
            </div>
            <CalendlyInlineEmbed vetId={vetId} calendly_url={vetInfo.calendly_url} />
            <div className="vet-profile-bio-container">
                <div className="vet-profile-bio">
                    <h2 className="vet-profile-bio-title">About {vetInfo.first_name}</h2>
                    <p className="vet-profile-bio-text">{vetInfo.bio}</p>
                </div>
            </div>
        </>
    );
}

export default VetProfile;