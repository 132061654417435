import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import DifferentialSelector from "../components/aiModals/DifferentialSelector";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SummaryWithDiagnosis from "../components/aiModals/SummaryWithDiagnosis";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { jwtDecode } from "jwt-decode";

function Differentials({ theme }) {
  const params = useParams();
  const aptId = params.id;
  const [apt, setApt] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [generatingTreatment, setGeneratingTreatment] = useState(false);
  const [treatmentData, setTreatmentData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function getUserFromToken() {
    const token = localStorage.getItem("token");
    if (!token) {
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }
  const userData = getUserFromToken();
  const userType = userData.sub.user_type;

  const fetchAppointment = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/appointment/${aptId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setApt(data);
        console.log(data);
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching appointment:", error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  const handleDifferentialSubmission = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    setGeneratingTreatment(true);
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/get-treatment-plan/${aptId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      console.log(response);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setTreatmentData(data);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error generating treatment plan:", error);
    } finally {
      setGeneratingTreatment(false);
    }
  }, [aptId, navigate, apiUrl]);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  if (isLoading || generatingTreatment) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          mt: "250px",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          {generatingTreatment ? "Generating Summary" : "Loading..."}
        </Typography>
      </Box>
    );
  }

  const datetime_utc = apt.datetime_utc
    ? new Date(apt.datetime_utc + "Z")
    : null;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };
  const localDateTime = datetime_utc
    ? datetime_utc.toLocaleString("en-US", options)
    : "N/A";
  const [date, time] = localDateTime.split(", ");

  const handleNavToDashboard = () => {
    navigate("/appointments");
  };

  return (
    <ThemeProvider theme={theme}>
      {userType === "vet" ? (
        <>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{
              ml: isMobile ? 1 : 2,
              mt: 2,
              textTransform: "none",
              color: "black",
              "&:hover": {
                color: "#3991da",
                backgroundColor: "transparent",
              },
            }}
            onClick={handleNavToDashboard}
          >
            Back to Medical Dashboard
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              height: "100%",
              backgroundColor: "#BDE9FB",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                p: isMobile ? 0 : 1,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  flex: 7,
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
                  borderRadius: "10px",
                  padding: isMobile ? 2 : 3,
                  backgroundColor: "#F9F9F9",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  overflow: "auto",
                  marginRight: isMobile ? 1 : 2,
                  marginLeft: isMobile ? 1 : 2,
                }}
              >
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  gutterBottom
                  sx={{
                    color: "black",
                    display: "inline-block",
                    textTransform: "uppercase",
                  }}
                >
                  Appointment Details
                </Typography>
                <Box>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Appointment Date:
                    </strong>{" "}
                    {date || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Appointment Time:
                    </strong>{" "}
                    {time || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Pet Name:
                    </strong>{" "}
                    {apt.pet?.name || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Pet Age:
                    </strong>{" "}
                    {apt.pet?.age || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Pet Weight:
                    </strong>{" "}
                    {apt.pet?.weight || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Pet Sex:
                    </strong>{" "}
                    {apt.pet?.sex || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Pet Breed:
                    </strong>{" "}
                    {apt.pet?.breed || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Reason for Visit:
                    </strong>{" "}
                    {apt.reason || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Owner Name:{" "}
                    </strong>
                    {`${apt.pet?.owner?.first_name || ""} ${
                      apt.pet?.owner?.last_name || ""
                    }`.trim() || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Owner Email:
                    </strong>{" "}
                    {apt.pet?.owner?.email || "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Primary Veterinarian:
                    </strong>{" "}
                    {`Dr. ${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ||
                      "N/A"}
                  </Typography>
                  <Typography variant={isMobile ? "body2" : "body1"} mb={2}>
                    <strong
                      style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                    >
                      Meeting Link:
                    </strong>{" "}
                    {apt.meeting_link || "N/A"}
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      p: 2,
                      border: "1px solid darkgrey",
                      backgroundColor: "#e7e7e7",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Recordings:
                    </Typography>
                    {apt?.appointment_notes?.map((note, index) => (
                      <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        sx={{ backgroundColor: "#fafafa" }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography>{note.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{note.notes}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  flex: 5, // may have to adjust to 12 for mobile
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
                  borderRadius: "10px",
                  padding: isMobile ? 2 : 3,
                  backgroundColor: "#F9F9F9",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  overflow: "auto",
                  mt: isMobile ? 2 : 0,
                  marginRight: isMobile ? 1 : 2,
                  marginLeft: isMobile ? 1 : 2,
                }}
              >
                <DifferentialSelector
                  theme={theme}
                  aptId={aptId}
                  onDifferentialSubmit={handleDifferentialSubmission}
                  generatingTreatment={generatingTreatment}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mt: isMobile ? 2 : 0, flex: 1, p: isMobile ? 1 : 2 }}>
            <Box
              sx={{
                boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
                borderRadius: "10px",
                padding: isMobile ? 1 : 3,
                backgroundColor: "#F9F9F9",
                height: "100%",
              }}
            >
              <SummaryWithDiagnosis aptId={aptId} theme={theme} />
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ mt: isMobile ? 2 : 0, flex: 1, p: isMobile ? 1 : 2 }}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{
              ml: isMobile ? 1 : 2,
              mt: 2,
              mb: 2,
              textTransform: "none",
              color: "black",
              "&:hover": {
                color: "#3991da",
                backgroundColor: "transparent",
              },
            }}
            onClick={handleNavToDashboard}
          >
            Back to Medical Dashboard
          </Button>
          <Box
            sx={{
              boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
              borderRadius: "10px",
              padding: isMobile ? 1 : 3,
              backgroundColor: "#F9F9F9",
              height: "100%",
            }}
          >
            <SummaryWithDiagnosis
              userType={userType}
              aptId={aptId}
              theme={theme}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default Differentials;
