import React, { useEffect, useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export default function SummaryWithDiagnosis({ aptId, theme, userType }) {
  const [summaryData, setSummaryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState({});
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchSummary = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/get-summary-with-diagnosis/${aptId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSummaryData(data);
        setEditableData(data);
        setLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching the summary:", error);
      setLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleChange = (field, value) => {
    setEditableData({
      ...editableData,
      [field]: value,
    });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/update-summary-with-diagnosis/${aptId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editableData),
          credentials: "include",
        }
      );
      console.log("editable data:", editableData);

      if (response.ok) {
        const data = await response.json();
        console.log("Summary updated:", data);
        setSummaryData(editableData);
        setIsEditing(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error updating the summary:", error);
    }
  };

  const handleEmailReport = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/email-summary-with-diagnosis/${aptId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Email sent:", data);
        alert("Email sent successfully!");
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email. Please try again.");
    }
  };

  const formatSummary = (summary) => {
    // Remove ** from category headers
    let formattedSummary = summary
      ?.replace(/\*\*([^*]+)\:\*\*/g, "$1:") // Remove ** from headers
      .replace(/\*\*/g, "") // Remove any remaining **
      .replace(/\*\*([^*]+)\:\*\*\n/g, "$1:\n"); // Ensure category names are followed by a colon and newline

    // Add indentation to list items
    formattedSummary = formattedSummary?.replace(
      /- /g,
      "&nbsp;&nbsp;&nbsp;&nbsp;- "
    ); // Add indentation before each list item

    return formattedSummary;
  };

  const formattedSummary = formatSummary(summaryData?.summary);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!summaryData) {
    return (
      <Typography variant="h6" color="error" align="center">
        No data available
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "#F9F9F9",
        borderRadius: "10px",
        maxHeight: "800px",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflow: "auto",
      }}
    >
      <Button
        variant="contained"
        sx={{
          mb: 2,
          borderRadius: "25px",
          color: "black",
          backgroundColor: "#0FBEB7",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#757575",
          },
        }}
        size="small"
        onClick={() => setIsEditing(!isEditing)}
      >
        {isEditing ? "Cancel" : "Edit"}
      </Button>
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
        <strong>Medical Record</strong>
      </Typography>
      <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
        Created by:{" "}
        {summaryData.veterinarian_first_name +
          " " +
          summaryData.veterinarian_last_name}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Pet Information
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={editableData.pet_name}
              onChange={(e) => handleChange("pet_name", e.target.value)}
            />
            <TextField
              label="Breed"
              fullWidth
              margin="normal"
              value={editableData.pet_breed}
              onChange={(e) => handleChange("pet_breed", e.target.value)}
            />
            <TextField
              label="Sex"
              fullWidth
              margin="normal"
              value={editableData.pet_sex}
              onChange={(e) => handleChange("pet_sex", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "10px" : "0px"}
            >
              <strong>Name:</strong> {summaryData.pet_name}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Breed:</strong> {summaryData.pet_breed}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Sex:</strong> {summaryData.pet_sex}
            </Typography>
          </>
        )}
      </Box>
      {/* Owner Information */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Owner Information
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Owner First Name"
              fullWidth
              margin="normal"
              value={editableData.owner_first_name}
              onChange={(e) => handleChange("owner_first_name", e.target.value)}
            />
            <TextField
              label="Owner Last Name"
              fullWidth
              margin="normal"
              value={editableData.owner_last_name}
              onChange={(e) => handleChange("owner_last_name", e.target.value)}
            />
            <TextField
              label="Owner Email"
              fullWidth
              margin="normal"
              value={editableData.owner_email}
              onChange={(e) => handleChange("owner_email", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
              fullWidth
            >
              <strong>Name:</strong>{" "}
              {summaryData.owner_first_name + " " + summaryData.owner_last_name}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"} fullWidth>
              <strong>Email:</strong> {summaryData.owner_email}
            </Typography>
          </>
        )}
      </Box>
      {/* Appointment Details */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Appointment Details
        </Typography>
        {isEditing ? (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
            >
              <strong>Date:</strong> {summaryData.appointment_date}
            </Typography>
            <TextField
              label="Appointment Reason"
              fullWidth
              multiline
              margin="normal"
              value={editableData.appointment_reason}
              onChange={(e) =>
                handleChange("appointment_reason", e.target.value)
              }
            />
          </>
        ) : (
          <>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              marginTop={isMobile ? "5px" : "0px"}
            >
              <strong>Date:</strong> {summaryData.appointment_date}
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              <strong>Reason:</strong> {summaryData.appointment_reason}
            </Typography>
          </>
        )}
      </Box>
      {/* Patient Summary */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Appointment Summary
        </Typography>
        {isEditing ? (
          <>
            <TextField
              label="Summary"
              fullWidth
              multiline
              margin="normal"
              value={editableData.summary}
              onChange={(e) => handleChange("summary", e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              variant="body2"
              sx={{ mb: 1, color: "#646464" }}
              component="div"
              dangerouslySetInnerHTML={{
                __html: formattedSummary?.replace(/\n/g, "<br />"),
              }}
            />
          </>
        )}
      </Box>
      {/* Selected Diagnoses */}
      <Box sx={{ mt: 4 }}>
        <Typography variant={isMobile ? "body" : "h6"} gutterBottom>
          Selected Diagnoses
        </Typography>
        {summaryData?.diagnoses_with_treatment?.length > 0 ? (
          <List>
            {summaryData.diagnoses_with_treatment.map((diag, index) => (
              <ListItem
                key={index}
                sx={{
                  width: "100%",
                  display: "block",
                }}
              >
                {isEditing ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column", // Stack fields vertically
                        gap: 2, // Add space between text fields
                      }}
                    >
                      <TextField
                        label="Diagnosis"
                        fullwidth
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        multiline
                        margin="normal"
                        value={diag.diagnosis}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].diagnosis = e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                      <TextField
                        label="Justification"
                        fullwidth
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        multiline
                        margin="normal"
                        value={diag.justification}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].justification =
                            e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                      <TextField
                        label="Treatment"
                        fullwidth
                        multiline
                        margin="normal"
                        sx={{
                          width: isMobile ? "100%" : "70%",
                        }}
                        value={diag.treatment}
                        onChange={(e) => {
                          const updatedDiagnoses = [
                            ...editableData.diagnoses_with_treatment,
                          ];
                          updatedDiagnoses[index].treatment = e.target.value;
                          setEditableData({
                            ...editableData,
                            diagnoses_with_treatment: updatedDiagnoses,
                          });
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <ListItemText
                    primary={<strong>Diagnosis: {diag.diagnosis}</strong>}
                    secondary={
                      <Box component="span" sx={{ color: "black" }}>
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          Justification:
                        </Typography>
                        {" " + diag.justification}
                        <br />
                        <Typography
                          component="span"
                          sx={{ fontWeight: "bold" }}
                        >
                          Treatment:
                        </Typography>
                        {" " + diag.treatment}
                      </Box>
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No diagnoses selected</Typography>
        )}
      </Box>
      {isEditing && (
        <Button
          variant="contained"
          sx={{
            mt: 2,
            borderRadius: "25px",
            color: "black",
            backgroundColor: "#0FBEB7",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#FFFFFF",
              color: "#757575",
            },
          }}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      )}
      {!isEditing && (
        <>
          <Typography
            variant={isMobile ? "body" : "h6"}
            gutterBottom
            sx={{ marginTop: 4 }}
          >
            Email Report:
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"}>
            Enter email address to send the report to (separate multiple emails
            with a comma):
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: isMobile ? "100%" : "75%",
              marginRight: isMobile ? "0" : "600px",
            }}
          >
            <TextField
              label="example@example.com"
              multiline
              fullWidth
              sx={{ width: isMobile ? "100%" : "50%", marginRight: "800px" }}
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                marginTop: 1,
                borderRadius: "25px",
                color: "black",
                backgroundColor: "#0FBEB7",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#757575",
                },
              }}
              onClick={handleEmailReport}
            >
              Email Report
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
