import * as React from "react";
import SelectDiagnosis from "./SelectDiagnosis";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function DifferentialSelector({ aptId, onDifferentialSubmit, theme }) {
  const [differentialList, setDifferentialList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || '';
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchDifferentials = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/v1/check-differential-list/${aptId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        if (data.exists) {
          setDifferentialList(data.differentials);
        }
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error fetching differentials:', error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchDifferentials();
  }, [fetchDifferentials]);

  const handleGenerateSummary = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    setIsGenerating(true);
    try {
      const response = await fetch(`${apiUrl}/get-differentials/${aptId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setDifferentialList(data.differentials);
      } else if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error generating differentials:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  console.log(differentialList);

  if (isLoading || isGenerating) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>
          {isGenerating ? "Generating AI Differentials" : "Loading..."}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {differentialList.length >= 1 ? (
        <>
          <Typography
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              color: "black",
              display: "inline-block",
              textTransform: "uppercase",
            }}
          >
            <strong>Patient Differentials:</strong>
          </Typography>
          <div>
            {differentialList?.map((differential, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <Typography>{differential.diagnosis}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{differential.justification}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <Box sx={{ mt: 6 }}>
            <Typography
              variant="body1"
              // component="h3"
              sx={{
                mt: 2,
                fontWeight: "bold",
                color: "black",
                // textShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                display: "inline-block",
                textTransform: "uppercase",
              }}
            >
              <strong>Select One or More: </strong>
            </Typography>
            <SelectDiagnosis
              differentialList={differentialList}
              aptId={aptId}
              onDifferentialSubmit={onDifferentialSubmit}
            />
          </Box>
          {isGenerating && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>Generating Treatment Plan</Typography>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            mt: isMobile ? "75px" : "300px",
            mb: isMobile ? "75px" : "0px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleGenerateSummary}
            sx={{
              borderRadius: "25px",
              backgroundColor: "#0FBEB7",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
          >
            Generate AI Differentials
          </Button>
        </Box>
      )}
    </Box>
  );
}
