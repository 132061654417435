import { useEffect, useState, useCallback } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";

function CalendlyInlineEmbed({ vetId, calendly_url }) {
    const [ownerId, setOwnerId] = useState(null);
    const [petId, setPetId] = useState(null);
    const [prefill, setPrefill] = useState({
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        customAnswers: {
            a1: "",
            a2: "",
            a3: "",
            a4: ""
        }
    });
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL || '';

    const calendlyUrl = calendly_url ? calendly_url : "https://calendly.com/angela-tolwani/30-minute-calendar-invite"
    // https://calendly.com/elizacohen5
    console.log(process.env.REACT_APP_CALENDLY_API_KEY)

    const checkSession = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/v1/check_session`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setOwnerId(data.owner_id);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error checking session:', error);
        }
    }, [navigate, apiUrl]);

    useEffect(() => {
        checkSession();
    }, [checkSession]);

    const fetchOwnerData = useCallback(async () => {
        if (!ownerId) return;

        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/owner/${ownerId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                setPrefill({
                    firstName: data.first_name,
                    lastName: data.last_name,
                    email: data.email,
                    customAnswers: {
                        a1: data.pets[0].name,
                        a2: data.pets[0].breed,
                        a3: data.pets[0].age,
                        a4: data.pets[0].sex
                    }
                });
                setPetId(data.pets[0].id);
            } else if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching owner data:', error);
        }
    }, [ownerId, navigate, apiUrl]);

    useEffect(() => {
        fetchOwnerData();
    }, [fetchOwnerData]);

    const handleEventScheduled = useCallback(async (e) => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const event_uri = e.data.payload.event.uri;
            console.log(event_uri)
            const invitee_uri = e.data.payload.invitee.uri;
            console.log(invitee_uri)

            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${process.env.REACT_APP_CALENDLY_API_KEY}`
                }
            };

            const eventResponse = await fetch(event_uri, options);
            console.log(eventResponse)
            if (!eventResponse.ok) {
                console.error('Event response error:', eventResponse);
                throw new Error('Failed to fetch event data');
            }
            const eventData = await eventResponse.json();

            const inviteeResponse = await fetch(invitee_uri, options);
            if (!inviteeResponse.ok) {
                throw new Error('Failed to fetch invitee data');
            }
            const inviteeData = await inviteeResponse.json();

            const appointmentData = {
                pet_id: petId,
                veterinarian_id: vetId,
                datetime_utc: eventData.resource.start_time,
                meeting_link: eventData.resource.location.join_url,
                reason: inviteeData.resource.questions_and_answers[4].answer,
                primary_vet: inviteeData.resource.questions_and_answers[5].answer,
                calendly_event_uri: event_uri
            }

            const appointmentResponse = await fetch(`${apiUrl}/api/v1/create_appointment`, {
                method: 'POST',
                body: JSON.stringify(appointmentData),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (!appointmentResponse.ok) {
                if (appointmentResponse.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    throw new Error('Failed to create appointment');
                }
            }
            // console.log('Appointment created successfully');
            
        } catch (error) {
            console.error('Error handling event scheduled:', error);
        }

    }, [petId, vetId, navigate, apiUrl]);

    useCalendlyEventListener({
        onEventScheduled: handleEventScheduled,
    });

   
    return (
        <>
            <div>
                <InlineWidget url={calendlyUrl} prefill={prefill} styles={{ height: '600px' }} />
            </div>
        </>
    )
}

export default CalendlyInlineEmbed;
