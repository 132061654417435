import React from 'react';
import { Box, Typography, Button, ThemeProvider, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const HomeContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})(({ theme, isMobile }) => ({
  height: 'calc(100vh - 79px)',
  width: '100vw',
  backgroundImage: isMobile ? 'url("/ailovet_home_page_mobile.png")' : 'url("/ailovet_home_page.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10px',
}));

const HomeContent = styled(Box)(({ theme }) => ({
  maxWidth: '1000px',
  marginLeft: '10%',
  textAlign: 'center',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    marginLeft: '5%',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0',
    width: '100%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#B3E5FC',
  color: 'black',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: "#757575",
  },
  borderRadius: '25px',
  padding: '15px 30px',
  fontSize: '1.5rem',
  fontWeight: 500,
  marginTop: '30px',
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    padding: '10px 20px',
    fontSize: '1rem',
  },
}));

function HomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if screen is mobile

  return (
    <ThemeProvider theme={theme}>
      <HomeContainer isMobile={isMobile}>
        <HomeContent>
          <Typography 
            variant="h1" 
            component="h1" 
            gutterBottom 
            sx={{ 
              textShadow: '4px 4px 8px rgba(0,0,0,0.6)', 
              marginBottom: '20px',
              fontSize: '5rem',
              fontWeight: 700,
              letterSpacing: '2px',
              [theme.breakpoints.down('sm')]: {
                fontSize: '3rem',
              },
            }}
          >
            AILOVET
          </Typography>
          <Typography 
            variant="h5" 
            gutterBottom 
            sx={{ 
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)', 
              marginBottom: '20px', 
              maxWidth: '800px',
              fontSize: '2.8rem',
              fontWeight: 400,
              letterSpacing: '1px',
              lineHeight: 1.2,
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
                maxWidth: '100%',
              },
            }}
          >
            Taking care of your pet's health has never been easier
          </Typography>
          <Typography 
            variant="body1" 
            paragraph 
            sx={{ 
              color: 'black', 
              marginBottom: '30px', 
              maxWidth: '800px',
              fontSize: '1.8rem',
              fontWeight: 400,
              lineHeight: 1.4,
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
                maxWidth: '100%',
              },
            }}
          >
            Faster, easier and more accessible<br />veterinary telehealth consultations
          </Typography>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <StyledButton variant="contained">
              Schedule Virtual Visit
            </StyledButton>
          </Link>
        </HomeContent>
      </HomeContainer>
    </ThemeProvider>
  );
}

export default HomePage;
