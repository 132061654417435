import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Paper, Grid, Box, CircularProgress, Alert, TextField, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '15px',
  background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)',
}));

function AdminAccountSettings({ theme }) {
  const [user, setUser] = useState(null);
  const [practice, setPractice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || '';

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/check_session`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        fetchPracticeData(userData.admin_id);
        // console.log('userData', userData);
        // console.log('admin_id', userData.admin_id);
      } else {
        console.error('Failed to fetch user data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const fetchPracticeData = async (adminId) => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/practice/${adminId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const practiceData = await response.json();
        // console.log(practiceData);
        setPractice(practiceData);
      } else {
        console.error('Failed to fetch practice data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching practice data:', error);
      setLoading(false);
    }
  };

  const handleRemoveVet = async (vetId) => {
    setError("");
    setSuccess("");
    try {
      const response = await fetch(`${apiUrl}/api/v1/remove_vet_from_practice/${vetId}`, {
        method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });

      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message);
        fetchPracticeData(user.admin_id);

      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error('Error removing veterinarian:', error);
      setError('An error occurred while removing the veterinarian.');
    }
  }


  if (!practice) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#BDE9FB", minHeight: "100vh", padding: 2 }}>
        <Container component="main" maxWidth="md">
          <StyledPaper elevation={3}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                color: "black",
                mb: 3,
                textTransform: "uppercase",
                textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)"
              }}
            >
              Account Settings
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>Practice Name: {practice.practice_name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>Unique Practice Code: {practice.practice_code}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Associated Veterinarians
                </Typography>
                <List>
                  {practice.veterinarians.map((vet, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`Dr. ${vet.first_name} ${vet.last_name}`} secondary={vet.email} />
                      <Button variant="contained" color="primary" size='small' sx={{ marginBottom: 1.5 }} onClick={() => handleRemoveVet(vet.id)}>Remove</Button>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
                    {error}
                  </Alert>
                </Grid>
              )}
              {success && (
                <Grid item xs={12}>
                  <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
                    {success}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </StyledPaper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default AdminAccountSettings;