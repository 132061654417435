import React, { useEffect, useState, useCallback } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import VetAppointmentRow from "./VetAppointmentRow";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewAppointment from "./NewAppointment/NewAppointment";
import { ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useNavigate } from "react-router-dom";

function VetAppointmentTable({ theme }) {
  const [apts, setApts] = useState([]);
  const [filteredApts, setFilteredApts] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [petOptions, setPetOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedPetName, setSelectedPetName] = useState("");
  const [vetOptions, setVetOptions] = useState([]);
  const [selectedVet, setSelectedVet] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 10 : 10);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  function getUserFromToken() {
    const token = localStorage.getItem("token");

    if (!token) {
      return null;
    }

    try {
      const decodedToken = jwtDecode(token);

      return decodedToken;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }
  const userData = getUserFromToken();

  const fetchAppointments = useCallback(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    fetch(`${apiUrl}/appointments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else if (r.status === 401) {
          throw new Error("Unauthorized");
        }
        throw new Error("Network response was not ok");
      })
      .then((aptArray) => {
        setApts(aptArray);
        setFilteredApts(aptArray);

        const vets = [
          ...new Set(
            aptArray.map(
              (apt) =>
                `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}`
            )
          ),
        ];
        setVetOptions(vets);

      })
      .catch((err) => {
        console.error(err);
        if (err.message === "Unauthorized") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  }, [navigate, apiUrl]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  useEffect(() => {
    let filtered = apts;
    if (selectedPetName) {
      filtered = filtered.filter((apt) => apt.pet.name === selectedPetName);
    }
    if (selectedVet) {
      filtered = filtered.filter(
        (apt) =>
          `${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ===
          selectedVet
      );
    }
    if (selectedDate) {
      filtered = filtered.filter((apt) => {
        const aptDate = new Date(apt.datetime_utc);
        return aptDate.toDateString() === selectedDate.toDateString();
      });
    }
    setFilteredApts(filtered);
  }, [selectedPetName, selectedVet, selectedDate]);

  // Handle Page Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedApts = filteredApts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const petIds = [...new Set(apts.map((apt) => apt.pet_id))];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchAppointments(); // Fetch appointments after closing the form
  };

  // Fetch pet name options based on user input
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (inputValue.length > 1) {
      fetch(
        `${apiUrl}/api/v1/pet-names?practice_admin_id=${apts[0].veterinarian.practice_admin_id}&q=${inputValue}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPetOptions(data);
        });
    }
  }, [inputValue, apiUrl]);

  // Handle filter change
  const handleFilterChange = (event, value) => {
    setSelectedPetName(value);
  };

  const handleVetChange = (event) => {
    setSelectedVet(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const resetDate = () => {
    setSelectedPetName("");
    setSelectedVet("");
    setSelectedDate(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ backgroundColor: "#BDE9FB", padding: isMobile ? 1 : 2 }}>
          <Typography
            variant="body1"
            sx={{
              color: "black",
              mb: isMobile ? 0.5 : 1,
              display: "inline-block",
              fontSize: isMobile ? "0.9rem" : "1rem",
            }}
          >
            Welcome, {userData.sub.username}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              mb: isMobile ? 0.5 : 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h4"}
                sx={{
                  color: "black",
                  mb: isMobile ? 0 : 1,
                  textTransform: "uppercase",
                }}
              >
                Appointments
              </Typography>
              <IconButton
                onClick={resetDate}
                sx={{
                  ml: isMobile ? 1 : 1.5,
                  mb: 1,
                  backgroundColor: "white",
                }}
                size="small"
              >
                <RestartAltIcon />
              </IconButton>
            </Box>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpen}
              sx={{
                mb: isMobile ? 1 : 2,
                borderRadius: "25px",
                backgroundColor: "#0FBEB7",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "#757575",
                },
              }}
            >
              Add Appointment
            </Button>
          </Box>

          <NewAppointment
            open={open}
            handleClose={handleClose}
            petIds={petIds}
            onAppointmentCreated={fetchAppointments}
          />

          {apts.length > 0 ? (
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                borderRadius: "15px",
                marginTop: isMobile ? 3 : 0,
              }}
            >
              <TableContainer
                sx={{
                  maxHeight: isMobile ? 1000 : 700,
                  overflowX: "auto",
                  overflowY: isMobile ? "auto" : "auto",
                }}
              >
                <Table stickyHeader aria-label="appointments table">
                  <TableHead>
                    <TableRow>
                    <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          width: isMobile ? 75 : 200,
                          whiteSpace: isMobile ? "normal" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Autocomplete
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            },
                          }}
                          options={petOptions}
                          getOptionLabel={(option) => option}
                          onInputChange={(e, value) => setInputValue(value)}
                          onChange={handleFilterChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={isMobile ? "Pet" : "Pet Name"}
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{
                                sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          width: isMobile ? 75 : 200,
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <DatePicker
                          label={isMobile ? "" : "Filter by Date"}
                          value={selectedDate}
                          onChange={handleDateChange}
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              InputLabelProps={{
                                sx: { fontSize: isMobile ? "0.75rem" : "1rem" },
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          width: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Time
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Reason for Visit
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="vet-select-label" shrink>
                            Veterinarians
                          </InputLabel>
                          <Select
                            onChange={handleVetChange}
                            labelId="vet-select-label"
                            value={selectedVet}
                            label="Veterinarians"
                            displayEmpty
                            sx={{
                              color: "black",
                              fontSize: isMobile ? "0.75rem" : "1rem",
                            }}
                          >
                            <MenuItem value="">
                              <em>All</em>
                            </MenuItem>
                            {vetOptions.map((vet, i) => (
                              <MenuItem
                                sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
                                key={i}
                                value={vet}
                              >
                                {vet}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          width: isMobile ? 75 : 150,
                          whiteSpace: isMobile ? "none" : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                          Status
                      </TableCell>
                    
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Summary
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          minWidth: isMobile ? 75 : 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Differentials
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedApts.map((apt) => (
                      <VetAppointmentRow theme={theme} key={apt.id} apt={apt} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredApts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={isMobile ? "" : "Rows per page"}
                rowsPerPageOptions={isMobile ? [] : [5, 10, 25, 50]}
                sx={{
                  fontSize: isMobile ? "small" : "medium",
                  ".MuiTablePagination-selectLabel, .MuiTablePagination-input":
                    {
                      fontSize: isMobile ? "small" : "medium",
                    },
                }}
              />
            </Paper>
          ) : (
            <p>No appointments to display</p>
          )}
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default VetAppointmentTable;
