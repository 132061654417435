import { Typography, Button, Box } from "@mui/material";

export default function PremiumUpgrade({ handleNavToAccountPage }) {
    return (
        <Box
          sx={{
            borderRadius: "10px",
            mx: 2,
            padding: 7,
            maxHeight: "100%",
            maxWidth: "100%",
            boxSizing: "border-box",
            overflow: "auto",
            textAlign: "center",
          }}
          mx={4}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "black",
              display: "inline-block",
            }}
          >
            <strong>Access Denied: Appointment Limit Reached </strong> <br />
          </Typography>
          <Typography variant="body2" sx={{ marginTop: 0.2 }}>
            Upgrade to Premium Subscription for Unlimited Appointments
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "25px",
              marginLeft: "5px",
              backgroundColor: "#0FBEB7",
              textTransform: "capitalize",
              marginTop: 2,
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#757575",
              },
            }}
            onClick={handleNavToAccountPage}
          >
            Upgrade to Premuim
          </Button>
        </Box>
    )
}
